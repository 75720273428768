import { useCallback } from "react";

import { emitter } from "@marvelapp/react-ab-test";

import { useUserDataStore } from "@/store/UserDataStore";

import { track } from "../helpers";

type Props = {
	testName: string;
	controlName?: string;
	forceTest?: boolean;
	variants?: {
		name: string;
	}[];
};

export const useABTest = () => {
	const [
		user,
		userExperiments,
		updateUserExperiments
	] = useUserDataStore(
		(state) => [
			state.user,
			state.userExperiments,
			state.updateUserExperiments
		]
	);

	const startABTest = useCallback(
		({
			testName,
			controlName = "Control",
			forceTest = false,
			variants,
		}: Props) => {
			if (
				forceTest &&
				(!userExperiments?.[testName] ||
					userExperiments?.[testName] === "Control")
			) {
				emitter.defineVariants(testName, [controlName, ...(variants?.map(({ name }) => name) || [testName]),]);
				emitter.setActiveVariant(testName, variants?.[0]?.name || testName);

				const theActiveVariant = emitter.getActiveVariant(testName);

				updateUserExperiments({ [testName]: theActiveVariant });

				track("Start Experiment", {
					name: testName,
					variant: theActiveVariant,
					coupon: user?.voucher?.code,
				});

				return theActiveVariant;
			}

			if (!user?.sales_person_id && !userExperiments?.[testName]) {
				emitter.defineVariants(testName, [controlName, ...(variants?.map(({ name }) => name) || [testName]),]);
				emitter.calculateActiveVariant(testName);

				const theActiveVariant = emitter.getActiveVariant(testName);

				updateUserExperiments({ [testName]: theActiveVariant });

				track("Start Experiment", {
					name: testName,
					variant: theActiveVariant,
					coupon: user?.voucher?.code,
				});

				return theActiveVariant;
			}

			if (!user?.sales_person_id) {
				emitter.calculateActiveVariant(testName);
				
				return emitter.getActiveVariant(testName);
			}

			return controlName;
		},
		[
			updateUserExperiments,
			user,
			userExperiments
		]
	);

	return {
		startABTest,
	};
};

/* eslint-disable indent */
import { useEffect, useMemo } from "react";

import { ErrorMessages, FieldErrors, Validation } from "@/types/inputFields";
import { ErrorMessage } from "@/components/FormElements/ErrorMessage";

import { useTracking } from "./useTracking";

type Props = {
	errorMessages?: ErrorMessages;
	validate?: Validation;
	name: string;
	addressee?: string;
	extraText?: string;
	errors?: FieldErrors;
};

export const useFunnelFieldErrors = ({
	errorMessages,
	name,
	validate,
	addressee,
	extraText,
	errors,
}: Props) => {
	const { trackFunnelError } = useTracking();
	useEffect(() => {
		trackFunnelError({
			fieldId: name,
			errors: errors || {},
		});
	}, [errors, name, trackFunnelError]);

	const errorMessageText = useMemo(() => {
		return {
			required: (
				<ErrorMessage>
					{errorMessages?.required
						? errorMessages?.required
						: `Please tell us ${
								addressee ? `${addressee}'s` : `your dog's`
						  } ${name}`}
				</ErrorMessage>
			),
			pattern: (
				<ErrorMessage>
					{errorMessages?.pattern ? (
						<>{errorMessages?.pattern}</>
					) : (
						<>{name}'s format is incorrect</>
					)}
				</ErrorMessage>
			),
			minLength: (
				<ErrorMessage>
					{errorMessages?.minLength ? (
						<>{errorMessages?.minLength}</>
					) : (
						<>
							{name} must be at least {validate?.minLength} characters
						</>
					)}
				</ErrorMessage>
			),
			maxLength: (
				<ErrorMessage>
					Must be no more than {validate?.maxLength} characters
				</ErrorMessage>
			),
			max: (
				<ErrorMessage>
					{errorMessages?.max ? (
						errorMessages.max
					) : (
						<>
							Sorry, the maximum {name} we support is {validate?.max}
							{extraText}
						</>
					)}
				</ErrorMessage>
			),
			min: (
				<ErrorMessage>
					{errorMessages?.min ? (
						errorMessages.min
					) : (
						<>
							Sorry, the minimum {name} we support is {validate?.min}
							{extraText}
						</>
					)}
				</ErrorMessage>
			),
			validate: (
				<ErrorMessage>
					{errorMessages?.validate ? (
						errorMessages.validate
					) : (
						<>Sorry, the value is not valid</>
					)}
				</ErrorMessage>
			),
		};
	}, [
		errorMessages?.required,
		errorMessages?.pattern,
		errorMessages?.minLength,
		errorMessages?.max,
		errorMessages?.min,
		errorMessages?.validate,
		addressee,
		name,
		validate?.minLength,
		validate?.maxLength,
		validate?.max,
		validate?.min,
		extraText,
	]);

	if (!name || !validate || !errors || !errors[name]) {
		return { errorMessage: null };
	}

	return {
		errorMessage:
			errorMessageText[errors[name].type as keyof typeof errorMessageText],
	};
};

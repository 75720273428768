import { useEffect, useState } from "react";

import c from "classnames";
import { useRouter } from "next/router";

import { Link } from "@/components/FormElements/Link";
import { HeaderTypes } from "@/types/layout";
import { ProgressBar } from "@/pagesComponents/Funnel/ProgressBar";

import Styles from "./header.module.scss";
import { HeaderNav } from "./HeaderNav";
import { useHeader } from "./useHeader";
import { AccountHeaderNav } from "./AccountHeaderNav";

import { OfferBanner } from "../OfferBanner";
import { Icon } from "../FormElements/Icon";

type Props = {
	showProgress?: boolean;
	children?: React.ReactNode;
	type?: HeaderTypes;
	inverse: boolean;
	headerBottomOffset?: boolean;
	showVoucher?: boolean;
	step?: number;
	totalSteps?: number;
	noPattern?: boolean;
	showIcons?: boolean;
	isLoadingStep?: boolean;
};

export const Header = ({
	showProgress,
	type = HeaderTypes.default,
	children,
	inverse = false,
	headerBottomOffset,
	step,
	totalSteps,
	showVoucher = true,
	noPattern = false,
	showIcons = false,
	isLoadingStep = false,
}: Props) => {
	const {
		mobileNavOpen,
		triggerMobileNav,
		itemOpen,
		openMenuItem,
		navItemClicked,
		animateProgressBarForwards,
	} = useHeader({
		step,
	});

	const [loadIcons, setLoadIcons] = useState(false);

	useEffect(() => {
		if (!loadIcons) {
			setLoadIcons(true);
		}
	}, [loadIcons]);

	const { pathname, push } = useRouter();

	const mainRoute = pathname.split("/")[1];
	const subRoute = pathname.split("/")[2];

	const isAccountPage = mainRoute === "account";
	const isAccountShop = subRoute === "shop";

	const isHero = type === "hero";
	const isFunnel = type === "funnel";
	const isDefault = type === "default";
	const isHeroMenu = type === "heroMenu";
	const isAccountOverlay = type === "overlay";

	const Nav = isAccountPage
		? AccountHeaderNav
		: HeaderNav;

	return (
		<div
			className={c(Styles.headerWrapper, {
				[Styles.bottomOffset]: headerBottomOffset,
				[Styles.loadingStep]: isLoadingStep,
			})}
		>
			<header
				className={c(Styles.header, {
					[Styles.heroHeader]: isHero,
					[Styles.heroMenuHeader]: isHeroMenu,
					[Styles.funnelHeader]: isFunnel,
					[Styles.headerDropped]: itemOpen !== "none" || mobileNavOpen,
					[Styles.heroHeaderInverse]: isHero && inverse,
					[Styles.spaceTop]: isFunnel || isHero,
					[Styles.loadingStep]: isLoadingStep,
					[Styles.isAccount]: isAccountPage,
					[Styles.fixedHeader]:
						!isFunnel && !isAccountPage && !isHero && !isHeroMenu,
				})}
			>
				<div className={c("container", Styles.container)}>
					{(isDefault || isHeroMenu) && (
						<div className={c(Styles.mobileNavBtnContainer)}>
							{!isAccountPage && (
								<button
									className={c(Styles.headerNavButton, {
										[Styles.isActive]: mobileNavOpen,
									})}
									onClick={triggerMobileNav}
								>
									Open Navigation<span></span>
								</button>
							)}
						</div>
					)}

					<div className={Styles.headerLogoContainer}>
						<Link classname={Styles.headerLogo} href="/">
							Pure Pet Food
						</Link>
					</div>

					{isAccountPage && (
						<div className={Styles.headerRightButton}>
							{isAccountShop
								? (
									<button
										className={c(Styles.headerAccountUserButton)}
										onClick={() => push("/account/shop/basket")}
									>
										<Icon icon="ShoppingBasket" width="32px" height="32px" />
									</button>
								)
								: (
									<button
										className={c(Styles.headerAccountUserButton)}
										onClick={() => push("/account/details")}
									>
										<Icon icon="User" width="32px" height="32px" />
									</button>
								)}
						</div>
					)}

					{(isFunnel || isHero) && showVoucher && (
						<div className={Styles.discountWrapper}>
							<OfferBanner type="header" noLink={true} fadeIn={false} />
						</div>
					)}

					{!isFunnel && !isHero && !isAccountOverlay && (
						<Nav
							itemOpen={itemOpen}
							mobileNavOpen={mobileNavOpen}
							navItemClicked={navItemClicked}
							openMenuItem={openMenuItem}
						/>
					)}
				</div>

				{showIcons && loadIcons && (
					<div
						className={c(Styles.headerImages, {
							[Styles.headerImagesOverlap]: noPattern,
						})}
					>
						{[...Array(16)].map((_, i) => (
							// eslint-disable-next-line @next/next/no-img-element
							<img
								className={c({
									[Styles.headerImage]: true,
									[Styles[`headerImage${i + 1}`]]: true,
								})}
								key={i}
								src={`/images/header/${i > 7
									? i - 7
									: i + 1}.png`}
								alt="headerImage"
							/>
						))}
					</div>
				)}

				<div className={Styles.content}>{children}</div>

				{showProgress && totalSteps && step && (
					<ProgressBar
						animateUp={animateProgressBarForwards}
						totalSteps={totalSteps}
						step={step}
					/>
				)}

				{!showProgress && !noPattern && (
					<div
						className={c(Styles.bottomBorder, {
							[Styles.bottomBorderInverse]: inverse,
						})}
					/>
				)}
			</header>
		</div>
	);
};

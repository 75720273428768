import Script from "next/script";
import c from "classnames";

import { HeaderTypes } from "@/types/index";

import Styles from "./layout.module.scss";
import { useLayout } from "./useLayout";

import { Header } from "../Header";
import { Footer } from "../Footer";
import { VoucherModal } from "../VoucherModal";
import { CookieBanner } from "../CookieBanner/CookieBanner";

type Props = {
	children: React.ReactNode;
	voucher?: string;
	setVoucher?: boolean;
	pointerEvent?: boolean;
	forceVoucher?: boolean;
	showProgress?: boolean;
	classname?: string;
	headerContent?: React.ReactNode;
	isHeroHeader?: boolean;
	hasLargerFooter?: boolean;
	hasFooter?: boolean;
	headerType?: HeaderTypes;
	bottomPad?: number;
	headerInverse?: boolean;
	headerBottomOffset?: boolean;
	noVoucher?: boolean;
	showVoucher?: boolean;
	step?: number;
	totalSteps?: number;
	noPattern?: boolean;
	showHeaderIcons?: boolean;
	isLoadingStep?: boolean;
	slug?: string;
};

export const Layout = ({
	children,
	voucher = "",
	showProgress,
	forceVoucher = false,
	headerType = HeaderTypes.default,
	headerContent = <></>,
	hasLargerFooter = false,
	hasFooter = false,
	classname = "",
	headerInverse = false,
	headerBottomOffset,
	bottomPad,
	noVoucher = false,
	showVoucher = true,
	step,
	totalSteps,
	noPattern = false,
	showHeaderIcons = false,
	isLoadingStep = false,
	slug
}: Props) => {
	const { showModal, user } = useLayout({
		forceVoucher,
		noVoucher,
		voucher,
		slug
	});

	return (
		<div className={c(Styles.body, { [classname]: !!classname })}>
			<div className={Styles.wrapper}>
				<Header
					showProgress={showProgress}
					type={headerType}
					inverse={headerInverse}
					headerBottomOffset={headerBottomOffset}
					showVoucher={showVoucher}
					step={step}
					totalSteps={totalSteps}
					noPattern={noPattern}
					showIcons={showHeaderIcons}
					isLoadingStep={isLoadingStep}
				>
					{headerContent}
				</Header>

				{showModal && (
					<VoucherModal voucher={user.voucher} referral={user.referral} />
				)}

				<div
					className={c(Styles.mainContent, {
						[Styles.hasLargerFooter]: hasLargerFooter && !hasFooter,
						[Styles.hasFooter]: hasFooter,
					})}
					style={{
						paddingBottom: `${bottomPad}px`,
					}}
				>
					{children}
				</div>

				{hasFooter && <Footer />}

				<CookieBanner />
			</div>

			<Script
				src="https://www.dwin1.com/28012.js"
				strategy="afterInteractive"
			/>
		</div>
	);
};

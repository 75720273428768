/* eslint-disable indent */
import { useCallback, useMemo, useState } from "react";

import { LineItem, Product } from "@/types/checkout";
import { useUserDataStore } from "@/store/UserDataStore";
import { useCheckout } from "@/hooks/useCheckout";
import { track } from "@/helpers/analytics";

type Props = {
	item: LineItem;
	onDelete: (id: LineItem["id"]) => void;
};

export const useBasketItem = ({ item, onDelete }: Props) => {
	const [checkout, user, setCheckout] = useUserDataStore((state) => [
		state.checkout,
		state.user,
		state.setCheckout,
	]);

	const { updateBasket, removeFromBasket } = useCheckout();

	const [checkoutError, setCheckoutError] = useState("");
	const [quantityIsLoading, setQuantityIsLoading] = useState("");

	const {
		recipes,
		recipesPrice,
		recipesPriceOriginal,
		fullPrice,
		fullPriceOriginal,
	} = useMemo(() => {
		const recipes = item.items.filter(
			(itemItem: Product) => itemItem.protein && !itemItem.is_treat
		);
		const recipesPrice =
			recipes.reduce((sum, recipe) => {
				return sum + recipe.price * recipe.quantity;
			}, 0) / 100;
		const recipesPriceOriginal =
			recipes.reduce((sum, recipe) => {
				return sum + recipe.original_price * recipe.quantity;
			}, 0) / 100;
		const fullPrice =
			item.items.reduce((sum, theItem) => {
				return sum + theItem.price * theItem.quantity;
			}, 0) / 100;
		const fullPriceOriginal =
			item.items.reduce((sum, theItem) => {
				return sum + theItem.original_price * theItem.quantity;
			}, 0) / 100;
		return {
			recipes,
			recipesPrice,
			recipesPriceOriginal,
			fullPrice,
			fullPriceOriginal,
		};
	}, [item.items]);

	const weight = useMemo(() => {
		const combinedWeight = item.items.reduce(
			(prev, current) => prev + current.weight,
			0
		);
		return combinedWeight === 0
			? null
			: combinedWeight < 1000
			? `${combinedWeight}g`
			: `${combinedWeight / 1000}kg`;
	}, [item.items]);

	const { extras, extrasPrice, extrasPriceOriginal, extrasCount } =
		useMemo(() => {
			if (!item.pet) {
				return {};
			}

			const treats = item.items.filter((itemItem) => itemItem.is_treat);

			return {
				extras: treats.map((itemItem, key) => {
					return (
						<span key={key}>
							{itemItem.product_title + " x" + itemItem.quantity}
						</span>
					);
				}),
				extrasPrice:
					treats.reduce((sum, treat) => {
						return sum + treat.price * treat.quantity;
					}, 0) / 100,
				extrasPriceOriginal:
					treats.reduce((sum, recipe) => {
						return sum + recipe.original_price * recipe.quantity;
					}, 0) / 100,
				extrasCount: treats.reduce((prev, curr) => prev + curr.quantity, 0),
			};
		}, [item.items, item.pet]);

	const updateItem = useCallback(
		async (qty: number, scoop: boolean, id?: string) => {
			id && setQuantityIsLoading(id);
			const response = await updateBasket(item.id, qty, scoop, id);

			if (response.errorMsg !== null || response.data === null) {
				setCheckoutError("Sorry, there appears to be a problem");
				return;
			}

			setCheckout(response.data);

			track("update in basket", {
				category: "basket",
			});
			setQuantityIsLoading("");
		},
		[item.id, updateBasket, setCheckout]
	);

	const onRemove = useCallback(
		async (itemItemID?: string) => {
			const response = await removeFromBasket(item.id, itemItemID);

			if (response.errorMsg !== null || response.data === null) {
				setCheckoutError("Sorry, there appears to be a problem");
				return;
			}

			setCheckout(response.data);
		},
		[item.id, removeFromBasket, setCheckout]
	);

	const onKeyDown = useCallback(
		(event: React.KeyboardEvent<HTMLInputElement>) => {
			if (event.key === "Enter") {
				onDelete(item.id);
			}
		},
		[item.id, onDelete]
	);

	return {
		recipes,
		recipesPrice,
		recipesPriceOriginal,
		extras,
		extrasPrice,
		extrasPriceOriginal,
		fullPrice,
		weight,
		checkout,
		user,
		onRemove,
		updateItem,
		extrasCount,
		fullPriceOriginal,
		quantityIsLoading,
		onKeyDown,
		checkoutError,
	};
};

import Styles from "./progressBar.module.scss";

type Props = {
	step: number;
	totalSteps: number;
	animateUp?: boolean;
};

export const ProgressBar = ({ step, totalSteps, animateUp = true }: Props) => {
	const preStep = animateUp
		? step - 1 > -1
			? step - 1
			: 0
		: step + 1 < totalSteps
		? step + 1
		: totalSteps;

	const animateWidth = `
    @keyframes animateWidth {
      0%   { width: ${`${(preStep / totalSteps) * 100}%`} }
      100% { width: ${`${(step / totalSteps) * 100}%`} }
    }
  `;
	return (
		<>
			<style>{animateWidth}</style>
			<div className={Styles.zigzagDivider}>
				<div className={Styles.zigzag}></div>
				<div
					className={Styles.zigzagProgress}
					style={{
						width: `${(step / totalSteps) * 100}%`,
						animation: "animateWidth 0.4s ease-in-out",
					}}
				></div>
			</div>
		</>
	);
};

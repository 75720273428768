import { useEffect, useState } from "react";

import { useRouter } from "next/router";
import { track } from "@amplitude/analytics-browser";

import { getSource } from "@/helpers/helperFunctions";
import { useFetchVoucher } from "@/hooks/useFetchVoucher";
import { useFunnelStore } from "@/store/FunnelStore";
import { useUserDataStore } from "@/store/UserDataStore";

type Props = {
	noVoucher?: boolean;
	forceVoucher?: boolean;
	voucher?: string;
	slug?: string;
};

export const useLayout = ({ noVoucher, forceVoucher, voucher, slug }: Props) => {

	const { showModal, method } = useFetchVoucher({
		dontCheck: noVoucher,
		forceVoucher,
		voucher,
	});

	const { query } = useRouter();

	const [user, updateUser] = useUserDataStore((state) => [state.user, state.updateUser,]);

	const email = useFunnelStore((state) => state.email);

	if (showModal) {
		track("RAF view link", {
			category: "RAF",
			method: method,
		});
	}

	const [tracked, setTracked] = useState(false);

	useEffect(() => {
		if (typeof window !== "undefined" && slug && !tracked && [
			"home",
			"purehome",
			"purehomeg",
			"purehomef",
			"purecompare",
			"puref"
		].includes(slug)) {

			console.log(slug);
				
			track("Start Experiment", {
				name: "Homepage Test Aug24",
				variant: slug,
			});

			setTracked(true);
		}
	}, [slug, tracked])

	// Track unique site visits
	try {
		if (
			typeof window !== "undefined" &&
			window.sessionStorage !== null &&
			window.sessionStorage.getItem("unique_site_visit") !== "logged"
		) {
			window.sessionStorage.setItem("unique_site_visit", "logged");
			track("Site visit", {
				category: "",
				email: user?.email || email,
			});
		}
	} catch (e) {
		console.log(e);
	}

	useEffect(() => {
		if (query.awc && user.awc !== query.awc) {
			updateUser({ awc: query.awc as string });
		}
	}, [
		query.awc,
		updateUser,
		user.awc
	]);

	useEffect(() => {
		const source = user
			? getSource(user.source)
			: null;

		if (source !== user.source && source) {
			updateUser({ source: source });
		}
	}, [
		updateUser,
		user,
		voucher
	]);

	return {
		showModal,
		user,
	};
};

/* eslint-disable indent */
import { useState, useEffect, useCallback } from "react";

import { useRouter } from "next/router";
import { isBefore } from "date-fns";

import { useUserDataStore } from "@/store/UserDataStore";
import { Referral, Voucher } from "@/types/pet";
import { getReferralData, getVoucherData } from "@/API/voucher";

import { addDiscountCode } from "../API";

type Props = {
	dontCheck?: boolean;
	forceVoucher?: boolean;
	voucher?: string;
};

export const useFetchVoucher = ({
	dontCheck = false,
	forceVoucher,
	voucher,
}: Props) => {
	const [user, checkout, setCheckout, updateUser] = useUserDataStore(
		(state) => [state.user, state.checkout, state.setCheckout, state.updateUser]
	);

	// STATES
	const [method, setMethod] = useState("");
	const [checked, setChecked] = useState(false);
	const [showModal, setShowModal] = useState(false);

	//ROUTER
	const router = useRouter();

	// UPDATE THE CHECKOUT VOUCHER
	const updateDiscountCode = useCallback(
		async (code?: string) => {
			if (code && checkout && checkout.id) {
				const updatedCheckout = await addDiscountCode(checkout.id, code);

				setCheckout(updatedCheckout);

				// CHECK FOR ERRORS WHEN SAVING
				if (updatedCheckout.errors) {
					const error =
						updatedCheckout?.errors[0]?.validation?.discount_code?.[0];
					return { error };
				}

				return {
					code:
						updatedCheckout?.discount?.current ||
						updatedCheckout?.discount_code,
					error: null,
				};
			}

			return {
				error: "An error occured, please try again.",
			};
		},
		[checkout, setCheckout]
	);

	// SAVE THE VOUCHER DATA
	const saveVoucher = useCallback(
		async (voucherData: Voucher) => {
			if (!voucherData) {
				return;
			}

			const voucherExpired = !isBefore(new Date(), voucherData.end_date);

			if (
				user.voucher?.code === process.env.NEXT_PUBLIC_DEFAULT_VOUCHER &&
				voucherExpired
			) {
				return;
			}

			if (voucherExpired) {
				const voucher = process.env.NEXT_PUBLIC_DEFAULT_VOUCHER
					? await getVoucherData(process.env.NEXT_PUBLIC_DEFAULT_VOUCHER)
					: null;

				updateUser({ voucher });
				updateDiscountCode(process.env.NEXT_PUBLIC_DEFAULT_VOUCHER);

				return;
			}

			const newUser = { voucher: voucherData };
			updateUser(newUser);

			if (
				checkout &&
				(checkout.lineItems?.length || 0) > 0 &&
				checkout.discount_code !== voucherData?.code
			) {
				updateDiscountCode(voucherData.code);
			}
		},
		[checkout, updateDiscountCode, updateUser, user.voucher?.code]
	);

	// SAVE THE REFERRAL DATA
	const saveReferral = useCallback(
		async (referralData: Referral) => {
			if (!isBefore(new Date(), referralData.scheme.discountCode.end_date)) {
				return;
			}

			const newUser = {
				voucher: referralData.scheme.discountCode,
				referral: referralData,
			};
			updateUser(newUser);
			setShowModal(true);
			if (
				checkout &&
				(checkout.lineItems?.length || 0) > 0 &&
				checkout.discount_code !== referralData.scheme.discountCode.code
			) {
				updateDiscountCode(referralData.scheme.discountCode.code);
			}
		},
		[checkout, updateDiscountCode, updateUser]
	);

	const setInitialChannel = useCallback(
		(discountCode: string) => {
			if (!discountCode) {
				return;
			}

			if (user.initialChannel) {
				return;
			}

			updateUser({ initialChannel: discountCode });
		},
		[updateUser, user.initialChannel]
	);

	// CHECK VOUCHER
	const checkVoucher = useCallback(
		async (
			voucher: string,
			referral: boolean = false,
			forceVoucher: boolean = false
		) => {
			if (!user || (dontCheck && !forceVoucher)) {
				return;
			}

			setInitialChannel(voucher);

			// IF CHECKING A REFERRAL
			if (referral) {
				const referralData = await getReferralData(voucher);
				setChecked(true);

				saveReferral(referralData);

				return;
			}

			// IF CHECKING A VOUCHER AND ITS FORCED - USER DOES NOT HAVE A REFERRAL
			if ((!user.voucher || forceVoucher) && !user.referral) {
				const voucherData = await getVoucherData(voucher);
				voucherData !== null && saveVoucher(voucherData);
				setChecked(true);

				return;
			}

			// RECHECKING A VOUCHER - IS THIS NEEDED?
			if (user?.voucher?.code && !checked) {
				// RECHECK VOUCHER
				const voucherData = await getVoucherData(user.voucher.code);
				setChecked(true);
				voucherData !== null && saveVoucher(voucherData);

				return;
			}

			return;
		},
		[checked, dontCheck, saveReferral, saveVoucher, setInitialChannel, user]
	);

	// IF VOUCHER SPECIFIED IN URL
	useEffect(() => {
		if (dontCheck || !router.isReady) {
			return;
		}

		const defaultVoucher = process.env.NEXT_PUBLIC_DEFAULT_VOUCHER
			? process.env.NEXT_PUBLIC_DEFAULT_VOUCHER
			: user.sales_person_id
			? "eventf2f"
			: "limited25";

		if (router.query.m) {
			const methods = {
				f: "facebook",
				w: "whatsapp",
				e: "email",
				s: "share",
				c: "copy",
				t: "text",
			};
			setMethod(
				router.query.m ? methods[router.query.m as keyof typeof methods] : ""
			);
		}

		const isReferral =
			router.query.f &&
			router.query.f !== user?.referral?.token &&
			!user?.accessToken;

		const hasVoucherInURL =
			router.query.v &&
			router.query.v !== user?.voucher?.code &&
			!user?.referral;

		switch (true) {
			case isReferral:
				checkVoucher(router.query.f as string, true, true);
				break;
			case hasVoucherInURL:
				const code = router.query.v as string;

				const mobileStringRemoved = code.includes("_MOBILE")
					? code.replace("_MOBILE", "")
					: code;

				const desktopStringRemoved = mobileStringRemoved.includes("_DESKTOP")
					? mobileStringRemoved.replace("_DESKTOP", "")
					: mobileStringRemoved;

				checkVoucher(desktopStringRemoved, false, true);
				break;
			default:
				checkVoucher(voucher || defaultVoucher, false, forceVoucher);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [router.isReady]);

	return {
		checkVoucher,
		saveVoucher,
		saveReferral,
		showModal,
		method,
	};
};

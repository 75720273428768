import { useState } from "react";

import c from "classnames";
import Link from "next/link";
import { useRouter } from "next/router";

import { useMediaQueries } from "@/hooks/useMediaQueries";

import Styles from "./footer.module.scss";

import { Icon } from "../FormElements/Icon";
import { MobileNavBar } from "../MobileNavBar";
import { EmailMailingList } from "../EmailMailingList";

const YEAR = new Date().getFullYear();

export const Footer = () => {
	const [footerOpen, setFooterOpen] = useState("none");
	const { isTablet,
		isMobile,
		isDesktop, } = useMediaQueries();

	const { pathname } = useRouter();

	const mainRoute = pathname.split("/")[1];

	const isAccountPage = mainRoute === "account";

	return (
		<>
			{isAccountPage && <MobileNavBar page={pathname.split("/")[2]} />}

			<footer
				className={c(Styles.wrapper, {
					[Styles.wrapperAccount]: isAccountPage,
				})}
			>
				<div className="container">
					<div className={Styles.column}>

						{(isMobile || isDesktop) && <EmailMailingList />}

						<div className={Styles.socials}>
							<h4 className={`${Styles.label} mobileSize`}>Follow us on social media</h4>

							<Icon
								icon="Instagram"
								width="24px"
								height="24px"
								onClick={() =>
									window.open(
										"https://www.instagram.com/pure_pet_food/?hl=en",
										"_blank"
									)
								}
							/>

							<Icon
								icon="Facebook"
								width="24px"
								height="24px"
								onClick={() =>
									window.open("https://www.facebook.com/Purepetfood/", "_blank")
								}
							/>

							<Icon
								icon="TikTok"
								width="24px"
								height="24px"
								onClick={() =>
									window.open("https://www.tiktok.com/@purepetfood", "_blank")
								}
							/>
						</div>
					</div>

					<div
						className={c(Styles.column, {
							[Styles.footerListOpen]: footerOpen === "pure",
						})}
					>
						<ul>
							<li
								className={c(Styles.footerListHead, Styles.footerListHeadDrop)}
								onClick={() =>
									setFooterOpen(footerOpen === "pure"
										? "none"
										: "pure")
								}
							>
								<h4 className="mobileSize">Pure</h4>

								<Icon icon="ChevronDown" />
							</li>

							<li>
								<Link href="/reviews">
									<a>Reviews</a>
								</Link>
							</li>

							<li>
								<Link href="/recipes">
									<a>Recipes</a>
								</Link>
							</li>

							<li>
								<Link href="/benefits">
									<a>Benefits</a>
								</Link>
							</li>

							<li>
								<Link href="/pet-panel">
									<a>Pet panel</a>
								</Link>
							</li>

							<li>
								<Link href="/about">
									<a>Our story</a>
								</Link>
							</li>

							<li>
								<Link href="/puppies">
									<a>Puppies</a>
								</Link>
							</li>
						</ul>
					</div>

					<div
						className={c(Styles.column, {
							[Styles.footerListOpen]: footerOpen === "Help",
						})}
					>
						<ul>
							<li
								className={c(Styles.footerListHead, Styles.footerListHeadDrop)}
								onClick={() =>
									setFooterOpen(footerOpen === "Help"
										? "none"
										: "Help")
								}
							>
								<h4 className="mobileSize">Help</h4>

								<Icon icon="ChevronDown" />
							</li>

							<li>
								<Link href="/blog/category/research">
									<a>Research</a>
								</Link>
							</li>

							<li>
								<Link href="/blog">
									<a>Blog</a>
								</Link>
							</li>

							<li>
								<Link href="/breeds">
									<a>Breeds</a>
								</Link>
							</li>

							<li>
								<Link href="/contact">
									<a>Get in touch</a>
								</Link>
							</li>

							<li>
								<Link href="/help">
									<a>Help centre</a>
								</Link>
							</li>
						</ul>
					</div>

					<div
						className={c(Styles.column, {
							[Styles.footerListOpen]: footerOpen === "information",
						})}
					>
						<ul>
							<li
								className={c(Styles.footerListHead, Styles.footerListHeadDrop)}
								onClick={() =>
									setFooterOpen(
										footerOpen === "information"
											? "none"
											: "information"
									)
								}
							>
								<h4 className="mobileSize">Information</h4>

								<Icon icon="ChevronDown" />
							</li>

							<li>
								<Link href="/account/dashboard">
									<a>My account</a>
								</Link>
							</li>

							<li>
								<Link href="/help/delivery-information">
									<a>Delivery information</a>
								</Link>
							</li>

							<li>
								<Link href="/help/privacy-and-cookie-policy">
									<a>Privacy policy</a>
								</Link>
							</li>

							<li>
								<Link href="/help/terms-and-conditions">
									<a>Terms &amp; conditions</a>
								</Link>
							</li>

							<li>
								<Link href="/help/cancellation-returns-refunds">
									<a>Returns</a>
								</Link>
							</li>

							<li>
								<Link href="/help/site-security">
									<a>Site security</a>
								</Link>
							</li>

							<li>
								<Link href="/sitemap">
									<a>Sitemap</a>
								</Link>
							</li>
						</ul>
					</div>

					{(isTablet && !isDesktop) && <EmailMailingList className={Styles.vipList} />}

					<div className={Styles.logoContainer}>
						<img
							className={Styles.logo}
							src="/images/logo.png"
							alt="Pure pet food"
						/>

						<p className={Styles.copyright}>© Pure Pet Food Ltd 2020-{YEAR}</p>
					</div>
				</div>
			</footer>
		</>
	);
};

export type FieldValidators = {
    pattern?: RegExp;
    errorMessages?: {
        required?: string;
        invalid?: string;
    };
}

export const emailValidator: FieldValidators = {
    pattern: /^\S+@\S+\.\S+$/,
    errorMessages: {
        invalid: "Please enter a valid email address",
        required: "Please enter an email address"
    }
}